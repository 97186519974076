<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd='DEVICE_CD'
            type="none"
            itemText="codeName"
            itemValue="code"
            name="deviceType"
            label="시스템 구분"
            v-model="searchParam.deviceType"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="로그인/로그아웃기간"
            name="period"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="로그인로그 이력"
          tableId="table"
          :columns="grid.columns"
          :data="grid.data"
          @linkClick="linkClick"
          :columnSetting="false"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'login-log',
  data() {
    return {
      grid: {
        columns: [
          // {
          //   name: 'sysLoginLogId',
          //   field: 'sysLoginLogId',
          //   label: '로그번호',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            align: 'center',
            sortable: true,
          },
          {
            name: 'loginTypeName',
            field: 'loginTypeName',
            label: '로그타입',
            style: 'width: 100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'connUserNm',
            field: 'connUserNm',
            label: '사용자',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'connUserToken',
          //   field: 'connUserToken',
          //   label: '사용자토큰',
          //   align: 'center',
          //   sortable: true,
          // },
          // {
          //   name: 'connErrorYn',
          //   field: 'connErrorYn',
          //   label: '에러여부',
          //   align: 'center',
          //   sortable: true,
          // },
          // {
          //   name: 'connErrorMsg',
          //   field: 'connErrorMsg',
          //   label: '에러메시지',
          //   align: 'center',
          //   sortable: true,
          // },
          // {
          //   name: 'connIp',
          //   field: 'connIp',
          //   label: '사용자IP',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'insertDt',
            field: 'insertDt',
            label: '로그인/로그아웃 일시',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'logoutDt',
          //   field: 'logoutDt',
          //   label: '로그아웃일시',
          //   align: 'center',
          //   sortable: true,
          // },
        ],
        data: [],
      },
      searchParam: {
        adminFlag: '',
        startYmd: '',
        endYmd: '',
        loginType: 'IN',
        deviceType: 'P',
      },
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      consultUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.consultUrl = selectConfig.sys.log.login.list.url;
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.consultUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>
